import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private toastr: ToastrService,
    private httpClient: HttpClient,
    private router: Router
  ) { }

  public showToast(message: string, type: any) {
    if (type == 1) {
      type = 'success';
    } else if (type == 0) {
      type = 'error';
    }
    switch (type) {
      case 'success':
        this.toastr.success(message);
        break;
      case 'info':
        this.toastr.info(message);
        break;
      case 'error':
        this.toastr.error(message);
        break;
      case 'warning':
        this.toastr.warning(message);
        break;
      default:
        this.toastr.success(message);
        break;
    }
  }

  getData(type: string, api: string, body: any = ''): Observable<any> {
    if (type == 'get') {
      let params = "";
      if (body) {
        params += "?";
        for (var key in body) {
          params += key + '=' + body[key] + '&';
        }
      }
      return this.httpClient.get(api + params);
    } else if (type == 'post') {
      return this.httpClient.post(api, body);
    }
    return;
  }

  uploadFile(formData) {
    let body = {
      apiType: 'uploadFile',
      formData: formData
    }
    let httpOptions = { responseType: 'text' as 'json' }

    this.httpClient.post<any>('/uploadfile', body, httpOptions).subscribe(res => {
      console.log(res);
    }, (err) => {
      console.log(err);
      this.showToast("File upload failed", false)
    })
  }

}
