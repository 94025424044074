import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/services';

@Component({
  selector: 'app-upload-claims',
  templateUrl: './upload-claims.component.html',
  styleUrls: ['./upload-claims.component.css']
})
export class UploadClaimsComponent implements OnInit {

  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit() {
  }

  onFileSelect(event) {
    console.log(event);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file);
      this.onImageSubmit(file);
    }
  }

  onImageSubmit(file) {
    var formData = new FormData;
    formData.append('file', file);
    this.commonService.uploadFile(formData);
  }

}
