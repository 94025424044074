import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ClaimsComponent } from './components/claims/claims.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { LayoutComponent } from './shared/components/layout/layout.component';

//guards
import { AuthGuard, NoAuthGuard } from './shared/guard/index';
var guards = [AuthGuard, NoAuthGuard];

//add custom interceptor
import { CommonHttpInterceptor } from './shared/services/http-interceptor/http.interceptor';
const httpInterceptor = { provide: HTTP_INTERCEPTORS, useClass: CommonHttpInterceptor, multi: true };

import { CommonService } from './shared/services';
const services = [CommonService];


//routing module
import { RouterModule, Routes } from '@angular/router';
import { UploadClaimsComponent } from './components/upload-claims/upload-claims.component';
import { ClaimDetailComponent } from './components/claims/claim-detail/claim-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
const appRoutes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [NoAuthGuard] },
  {
    path: '', component: LayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent, },
      { path: 'claims', component: ClaimsComponent },
      {path: 'claim-detail/:id',component:ClaimDetailComponent},
      { path: 'reports', component: ReportsComponent },
      { path: 'upload-claims', component: UploadClaimsComponent }
    ]
  },
];

const locationStartegy = { provide: LocationStrategy, useClass: HashLocationStrategy };

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ReportsComponent,
    ClaimsComponent,
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    UploadClaimsComponent,
    ClaimDetailComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-bottom-center",
      toastClass: "ngx-toastr custom-toastr"
    }),
    RouterModule.forRoot(appRoutes, { enableTracing: true }),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgxSpinnerModule
  ],
  providers: [...services, ...guards, httpInterceptor, locationStartegy],
  bootstrap: [AppComponent]
})
export class AppModule { }
