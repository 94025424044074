import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService, CommonService } from '../../services/index';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
    private commonService: CommonService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let isAuthenticated = this.userService.isAuthenticated();
    if (isAuthenticated) {
      // logged in so return true
      return isAuthenticated;
    }

    // not logged in so redirect to login page with the return url
    this.commonService.showToast('Please login to continue', 'error');
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });

    // return true;
  }
}
