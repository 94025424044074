import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/services/index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.css']
})
export class ClaimsComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private route: Router,
  ) {
    this.getClaims();
  }

  public selectedClaim: any;

  // claimsList = [
  //   {
  //     claimNo: "SERCON226256",
  //     imei: "863287047936959",
  //     policyStartDate: "06-08-2019",
  //     policyEndDate: "03-02-2020",
  //     mobilePurchaseDate: "06-08-2019",
  //     storeName: "DILSUKHNAGAR 4",
  //     policyType: "Screen Protection",
  //     masterPolicyNumber: "AMS",
  //     certificateOfInsurance: "AMSP75900657936959",
  //     make: "XIAOMI",
  //     model: "REDMI NOTE 7 PRO  4GB/64GB BLUE",
  //     cost: "14499",
  //     policyStatus: "Current",
  //   },
  //   {
  //     claimNo: "SERCON226326",
  //     imei: "351578106369838",
  //     policyStartDate: "05-11-2018",
  //     policyEndDate: "04-11-2019",
  //     mobilePurchaseDate: "05-11-2018",
  //     storeName: "As Rao",
  //     policyType: "Screen Protection",
  //     masterPolicyNumber: "AMS",
  //     certificateOfInsurance: "AMSP01446424",
  //     make: "Samsung",
  //     model: "Samsung A7 2018 Blue(OC/6/128/6)",
  //     cost: "26990",
  //     policyStatus: "-",
  //   },
  //   {
  //     claimNo: "SERCON226414",
  //     imei: "356964091483120",
  //     policyStartDate: "24-09-2019",
  //     policyEndDate: "22-09-2020",
  //     mobilePurchaseDate: "24-09-2019",
  //     storeName: "Maredpally Secunderabad",
  //     policyType: "AD+LD 2.0",
  //     masterPolicyNumber: "SCL0000005200100AMTRUST",
  //     certificateOfInsurance: "AMTADII67515631483120",
  //     make: "NOKIA",
  //     model: "NOKIA 8.1 IRON(OC/4/64/6.18)",
  //     cost: "14931",
  //     policyStatus: "Current",
  //   }
  // ]
  claimsList = [];

  ngOnInit() {
  }

  public openClaimDetails(claim: any) {
    this.selectedClaim = claim;
  }

  getClaims() {
    this.commonService.getData('get', '/getallclaims').subscribe(res => {
      if (res.status) {
        this.claimsList = res.response;
      }
    });
  }
}
