import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../services';

@Injectable()
export class NoAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let isAuthenticated = this.userService.isAuthenticated();
    if (!isAuthenticated) {
      // logged in so return true
      return !isAuthenticated;
    }

    //logged in so redirect to login page with the return url
    this.router.navigate(['/dashboard']);

    //overriding authentication
    return !isAuthenticated;
  }
}
