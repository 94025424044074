import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/services/index'
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.createLoginForm();
  }

  ngOnInit() {
  }

  createLoginForm() {
    this.loginForm = this.fb.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  onLoginFormSubmit(formData) {
    // let body = {
    //   username: "Zarar",
    //   password: "123"
    // }
    if (!this.loginForm.valid) {
      this.commonService.showToast("Please add the required fields", "warning");
      return false;
    }
    this.commonService.getData('post', '/login', formData).subscribe(res => {
      if (res.status) {
        localStorage.setItem('amu', btoa(JSON.stringify(res.response)));
        this.router.navigate(['/dashboard']);
      }
    });
  }

}
