import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../services/index';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output('onHamburgerClick') onHamburgerClick = new EventEmitter;

  public isMenuOpen: boolean = true;
  userName: string = this.userService.getUserData('userName');

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
  }

}
