import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService, UserService } from '../../../shared/services/index';
import { Router, ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-claim-detail',
  templateUrl: './claim-detail.component.html',
  styleUrls: ['./claim-detail.component.css']
})
export class ClaimDetailComponent implements OnInit {
  claimsList = [];
  @Input('claimDetail') claimDetail: any;
  //@Output('onCloseDetailClick') onCloseDetailClick = new EventEmitter;
  public areDetailsEditable: boolean = false;
  public state: boolean = true;
  public hidePanelZero: boolean = true;
  public showPanelZero: boolean = false;
  public hidePanelOne: boolean = true;
  public showPanelOne: boolean = false;
  public pickupVendors = [];
  public arcVendors = [];
  public dispatchVendors = [];
  public claimStatuses = [];
  public claimSubStatuses = [];
  public selectedPickupVendor: string = "";
  public selectedArcVendor: string = "";
  public selectedDispatchVendor: string = "";
  public selectedClaimStatusId: any = "";
  public selectedClaimSubStatusId: any = "";
  public selectedClaimStatusName: string;
  public selectedClaimSubStatusName: string;
  public selectedClaimSubStatus: string = "";
  public comments: string;
  public ccnumber: any = "";
  public bsDatePickerConfig: Partial<BsDatepickerConfig>;
  public claimsUpdateDate = new Date();
  public claimsUpdateTime = new Date();

  constructor(
    private commonService: CommonService,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
  ) {
    this.activeRoute.paramMap.subscribe(params => {
      this.ccnumber = params.get("id");
    });
    this.getDropdownListData();
  }

  ngOnInit() {
    this.bsDatePickerConfig = Object.assign({}, { containerClass: 'theme-red', dateInputFormat: 'DD-MM-YYYY' })
  }

  checkDropDownData() {
    if (!this.claimDetail) {
      return false;
    }
    if (this.claimDetail.claim_status) {
      this.selectedClaimStatusName = this.claimDetail.claim_status;
      // let claimStatus = this.claimStatuses.filter(item => item.claimstatus == this.claimDetail.claim_status);
      // this.selectedClaimStatusId = claimStatus[0].id;
      // this.getSubStatus(this.selectedClaimStatusId);
    }
    if (this.claimDetail.claim_sub_status) {
      let claimSubStatus = this.claimSubStatuses.filter(item => item.claimSubstatus == this.claimDetail.claim_sub_status);
      this.selectedClaimSubStatusId = claimSubStatus[0].id;
      this.selectedClaimSubStatus = claimSubStatus[0].id;
      this.selectedClaimSubStatusName = this.claimDetail.claim_sub_status;
    }
    if (this.claimDetail.comments) {
      this.comments = this.claimDetail.comments;
    }
    if (this.claimDetail.pickup) {
      this.selectedPickupVendor = this.claimDetail.pickup;
    }
    if (this.claimDetail.dispatch) {
      this.selectedDispatchVendor = this.claimDetail.dispatch;
    }
    if (this.claimDetail.arc) {
      this.selectedArcVendor = this.claimDetail.arc;
    }
    if (this.claimDetail.manual_datetime) { 
      this.claimsUpdateDate = new Date(this.claimDetail.manual_datetime);
      this.claimsUpdateTime = new Date(this.claimDetail.manual_datetime);
    }
  }

  getDropdownListData() {
    this.commonService.getData('get', '/getallPickupData').subscribe(res => {
      this.pickupVendors = res.response;
    });
    this.commonService.getData('get', '/getallarcData').subscribe(res => {
      this.arcVendors = res.response;
    });
    this.commonService.getData('get', '/getallDispatchData').subscribe(res => {
      this.dispatchVendors = res.response;
    });
    /* this.commonService.getData('get', '/getClaimStatus').subscribe(res => {
       this.claimStatuses = res.response;
       // set values if available
       this.checkDropDownData();
     });*/
    this.commonService.getData('post', '/getClaimSubStatus').subscribe(res => {
      this.claimSubStatuses = res.response;
      this.getClaimsDetails();
    });
  }

  getSubStatus(claimId: number) {
    this.claimSubStatuses = [];
    this.commonService.getData('get', '/getClaimSubStatus/' + claimId).subscribe(res => {
      this.claimSubStatuses = res.data;
    });
    console.log(this.selectedClaimStatusId);
    let selectedClaimStatus = this.claimStatuses.filter(item => item.id == claimId);
    this.selectedClaimStatusName = selectedClaimStatus[0].claimstatus;
    console.log(this.selectedClaimStatusName);
  }

  getClaimStatusBySubStatus(claimSubId: number) {
    let selectedClaimStatus: any;
    let selectedClaimSubStatus = this.claimSubStatuses.filter(item => item.id == claimSubId);
    this.selectedClaimStatusId = selectedClaimSubStatus[0].claimStatusId;
    this.commonService.getData('post', '/getClaimStatusById', { id: this.selectedClaimStatusId }).subscribe(res => {
      selectedClaimStatus = res.response;
      this.selectedClaimStatusName = selectedClaimStatus[0].claimstatus;
      this.selectedClaimStatusId = selectedClaimStatus[0].id;
      console.log(this.selectedClaimStatusName);
      console.log(this.selectedClaimStatusId);
    });
    this.selectedClaimSubStatusName = selectedClaimSubStatus[0].claimSubstatus;
  }

  public updatedClaimStatus() {
    console.log(this.claimsUpdateDate);
    console.log(this.claimsUpdateTime);

    // get date as per format
    // console.log(new Date(this.claimsUpdateDate).getFullYear() + "-" + this.makeDoubleDigit((new Date(this.claimsUpdateDate).getMonth() + 1)) + "-" + this.makeDoubleDigit(new Date(this.claimsUpdateDate).getDate());

    // get time as per format
    // console.log(this.makeDoubleDigit(new Date(this.claimsUpdateTime).getHours()) + ":" + this.makeDoubleDigit(new Date(this.claimsUpdateTime).getMinutes()) + ":" + this.makeDoubleDigit(new Date(this.claimsUpdateTime).getSeconds()));

    // set date in variable
    let claimUpdateDateTime = new Date(this.claimsUpdateDate).getFullYear() + "-" + this.makeDoubleDigit((new Date(this.claimsUpdateDate).getMonth() + 1)) + "-" + this.makeDoubleDigit(new Date(this.claimsUpdateDate).getDate());

    // set time in variable
    claimUpdateDateTime += " " + this.makeDoubleDigit(new Date(this.claimsUpdateTime).getHours()) + ":" + this.makeDoubleDigit(new Date(this.claimsUpdateTime).getMinutes()) + ":" + this.makeDoubleDigit(new Date(this.claimsUpdateTime).getSeconds());
    // console.log(claimUpdateDateTime);
    // return;
    let body = {
      claims_claims_number: this.claimDetail.claims_claims_number,
      pickup: "",
      dispatch: "",
      arc: "",
      claim_status: this.selectedClaimStatusName,
      claim_sub_status: this.selectedClaimSubStatusName,
      updated_by: this.userService.getUserData('userId'),
      comments: this.comments,
      manual_datetime: claimUpdateDateTime
    }
    console.log(body);
    this.commonService.getData('post', '/updateClaimMaster/' + this.claimDetail.claims_claims_number, body).subscribe(res => {
      console.log(res);
    })
  }

  public updatedVendorDetails() {
    let body = {
      claims_claims_number: this.claimDetail.claims_claims_number,
      pickup: this.selectedPickupVendor,
      dispatch: this.selectedDispatchVendor,
      arc: this.selectedArcVendor,
      claim_status: "",
      claim_sub_status: "",
      updated_by: this.userService.getUserData('userId'),
      comments: "",
      manual_datetime: ""
    }
    console.log(body);
    this.commonService.getData('post', '/updateClaimMaster/' + this.claimDetail.claims_claims_number, body).subscribe(res => {
      // console.log(res);
    })
  }
  getClaimsDetails() {
    this.commonService.getData('get', '/getclaimdetails/' + this.ccnumber).subscribe(res => {
      if (res.status) {
        this.claimDetail = res.response ? res.response[0] : [];
        this.checkDropDownData();
      }
    });
  }

  changeStatus($event) {
    if ($event.panelId == "ngb-panel-0") {
      switch ($event.nextState == false || $event.nextState == true) {
        case $event.nextState == true:
          this.hidePanelZero = true;
          this.showPanelZero = false;
          break;
        case $event.nextState == false:
          this.hidePanelZero = false;
          this.showPanelZero = true;
          break;
      }
    }
    else {
      switch ($event.nextState == false || $event.nextState == true) {
        case $event.nextState == true:
          this.hidePanelOne = true;
          this.showPanelOne = false;
          break;
        case $event.nextState == false:
          this.hidePanelOne = false;
          this.showPanelOne = true;
          break;
      }
    }
  }

  makeDoubleDigit(value) {
    if (value <= 9) {
      value = "0" + value;
    }
    return value;
  }
}
