import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/index';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  public isMenuOpen = true;
  // public activePage = "dashboard"
  public menuItems = [
    { name: "Dashboard", icon: "pe-7s-rocket", component: "dashboard", access: "read" },
    { name: "Claims", icon: "pe-7s-eyedropper", component: "claims", access: "read" },
    { name: "Upload Claims", icon: "pe-7s-eyedropper", component: "upload-claims", access: "upload" },
    { name: "Reports", icon: "pe-7s-display2", component: "reports", access: "read" }
  ]
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  logout() {
    localStorage.removeItem('amu');
    this.router.navigate(['/login']);
  }

}
