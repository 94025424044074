import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private router: Router
  ) { }

  public logout() {
    localStorage.removeItem('amtu');
    this.router.navigate(['/login']);
  }

  public getUserData(key: string) {
    if (!localStorage.getItem('amu')) {
      return false;
    }
    let data: any = ""
    let userData = JSON.parse(atob(localStorage.getItem('amu')));
    let decodedToken = jwt_decode(userData.token);
    switch (key) {
      case 'token':
        data = userData.token;
        break;
      case 'userName':
        data = userData.user.username;
        break;
      case 'email':
        data = userData.user.user_email;
        break;
      case 'role':
        data = decodedToken.role;
        break;
      case 'userId':
        data = userData.user.id;
        break;
      case 'userLevel':
        data = decodedToken.userLevel;
        break;
    }
    return data;
  }

  public isAuthenticated() {
    if (this.getUserData('token')) {
      return true;
    }
    return false;
  }

  hasEditAccess(access) {
    // 1 = Admin | 2 = Supervisor | 3 = User
    if (access == 'read') {
      return true;
    }
    if (access == 'upload') {
      return this.getUserData('userLevel') == 1 ? true : false;
    }
    return [1, 2].includes(this.getUserData('userLevel')) ? true : false;
  }
}
