import { Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { UserService, CommonService } from "../index";
import { environment } from "../../../../environments/environment";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class CommonHttpInterceptor implements HttpInterceptor {
    private baseUrl;
    constructor(
        private inj: Injector,
        private router: Router,
        private commonService: CommonService,
        private spinner: NgxSpinnerService
    ) {
        this.baseUrl = environment["baseUrl"];
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.spinner.show();
        let userService = this.inj.get(UserService);
        let headers = req.headers;
        headers = headers
            .set("Content-Language", "en");
        if (userService.isAuthenticated()) {
            headers = headers
                .set("Authorization", "Bearer " + userService.getUserData('token'));
            if (req.body && req.body.apiType != 'uploadFile') {
                // headers = headers.set("Content-Type", "multipart/form-data");
                headers = headers.set("Content-Type", "application/json");
            }
            //  else {
            //     headers = headers.set("Content-Type", "application/json");
            // }

        }


        let reqCloned: any;
        if (req.body && req.body.apiType == 'uploadFile') {
            reqCloned = req.clone({
                url: this.baseUrl + req.url,
                body: req.body.formData,
                headers: headers
            });
        } else {
            reqCloned = req.clone({
                url: this.baseUrl + req.url,
                headers: headers
            });
        }

        if (req.url.search("assets") > 0) {
            reqCloned = req;
        }

        return next.handle(reqCloned).pipe(tap(
            (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.spinner.hide();
                    // show message if available
                    let resBody: any = event.body;
                    console.log(typeof(resBody));
                    if (typeof(resBody) == "string") {
                        resBody = JSON.parse(resBody);
                    }
                    if (resBody && resBody.message) {
                        this.commonService.showToast(resBody.message, resBody.status);
                    }
                }
            },
            (err: any) => {
                //handle errors in global section
                if (err instanceof HttpErrorResponse) {
                    this.spinner.hide();
                    if (err.error.code === 401) {
                        // redirect to the login route
                        // or show a modal
                        userService.logout();
                        return;
                    }

                    if (err.error && err.error.code && err.error.message) {
                        this.commonService.showToast(err.error.message, "error");
                    }
                }
            }
        ));
    }
}